.adisyonContainer {
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    flex-flow: row;
    overflow: hidden;
    width: 100%;
}

 
/*** Check Style***/

td,
th {
    padding: 2px
}

.adisyonContainer>.tableContainer {
    padding: 0.3rem;
    width: 100%;
    margin: 1px;
    color: black;
    display: block;
}

 .titleinfo {
    width: 100% !important;
    margin-bottom: 3px;
    padding: 5px;
    border-radius: 8px;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between;
    background-color: #D40216 !important;
}

.titleinfo > .totalTable {
    border: 2px dashed grey;
    padding-left: 3px;
    flex-direction: column !important;
}
.titleinfo > .singleTotal {
    display: flex !important;
    flex-direction: row !important;
}
.titleinfo > .remainingTable{
    border: 2px dashed grey;
    padding-left: 3px;
    flex-direction: column !important;
    margin-left: 4px;
}
.tableContainer>.Tablebox {
    height: fit-content;
    max-height: calc(67vh - 69px);
    width: 100%;
    display: block;
    border-radius: 8px;
    overflow-y: scroll;
}
@media ((min-height:660px) and (max-height: 770px)) {
    .adisyonContainer>.tableContainer>.Tablebox {
        max-height: calc(64vh - 69px);
    }
  }
  @media (max-height: 660px) {
    .adisyonContainer>.tableContainer>.Tablebox {
        max-height: calc(61vh - 69px);
    }
  }

.tableContainer>.Tablebox>.adisyonTable {
    float: left;
    background: white;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
}

.adisyonContainer>.tableContainer>.Tablebox>.adisyonTable>thead>tr {

}

.titleinfo {
    font-size: 1rem;
    background-color: white !important;
}

.tableContainer>.Tablebox>.adisyonTable>tbody>tr {
    border-collapse: collapse;
    border-style: hidden;
    font-size: 0.9rem;
    font-weight: 500;
    align-self: center;
    border-bottom: 1px solid #FF0000 !important;
}
.tableContainer>.Tablebox>.adisyonTable>tbody>tr> td{

}
.tableContainer>.Tablebox>.adisyonTable .adisyonThead {
    max-width: 35px !important;
    width: 100px !important;
}

.tableContainer>.Tablebox>.adisyonTable .tableProductCount {
    max-width: 35px;
}


/* Right Button Style */

.adisyonContainer>.rightButtons {
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    float: right;
    width: 35%;
    margin: 1%;
}

.adisyonContainer>.rightButtons>button {
    border-radius: 6px;
    position: relative;
    background-color: hsl(3, 81%, 46%);
    color: white;
    margin-bottom: 6px;
    min-height: 55px;
    height: auto;
    border: none;
}

.adisyonContainer>.tableContainer>.buttonBottom {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: flex-start;
    width: 100%;
    margin-top: 0.5rem;
}

.adisyonContainer>.tableContainer>.buttonBottom>button {
    border: none;
    margin: 0 auto;
    border-radius: 4px;
    text-align: center;
    background-color: hsl(3, 81%, 46%);
    color: white;
    font-size: 18px;
    font-weight: 500;
    width: 23%;
    margin-top: 0.3vh;
    margin-bottom: 0;
    margin-left: 0.4vw;
    margin-right: 0;
    height: 9vh;
    margin-bottom: 6px;
}

.adisyonContainer>.tableContainer>.buttonBottom>button:hover,
.adisyonContainer>.rightButtons>button:hover {
    background-color: hsla(3, 83%, 56%, 0.672);
    cursor: pointer;
}

table {
    border-collapse: collapse;
    border-style: hidden;
    /* hide standard table (collapsed) border */
}

.caption {
width: 200px !important;
text-align: left;
grid-column: none;
}