
.takeaway-headers{
    display: flex;
    flex-direction: row;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
    color: white
  }
  .takeaway-headers>.left{
    width: 70vw;
  }
  .takeaway-headers>.left>.operations{
    display: flex;
    flex-direction: row;
    padding-bottom: 1vh;
  }
  
  .takeaway-headers>.left>.operations>button{
    font-size: 1vw;
    font-weight: 700;
    width: 6vw;
    height: 9vh;
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
    border-radius: 8px;
    margin-left: 0.5vw;
    padding-left: 0.1vw;
    padding-right: 0.1vw;
    background-image: linear-gradient(to bottom right, red, orange);
  }
  
  .takeaway-headers>.left>.search{
    background-color: red;
    margin-left: 0.5vw;
  }
  
  
  .takeaway-headers>.right{
    width: 30vw;
    display: grid;
    padding-left: 0.75vw;
    padding-right: 1vw;
    padding-bottom: 0.5vh;
    grid-template-columns: 14vw 14vw;
    grid-gap: 1vh;
  }
  .takeaway-headers>.right>button{
    border-radius: 8px;
    background-color: green;
    font-size: 1.5vw;
  }
  .col1-2{
    grid-column: 1/3;
    font-size: 2vw !important; 
    background-color: red !important;
  }
  
  
  
  .takeaway-bodys{
    margin-left: 1vw;
    margin-right: 1vw;
    margin-top: 1vh;
    margin-bottom: 1vh;
    height: 44vh;
    border-radius: 16px;
    background-color: #687079;
    color: white;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  
  .takeaway-bodys>table{
    width: 100%;
  }
  .takeaway-bodys>table>thead>tr>th{
    background-color: red;
    border: solid 1px;
  }
  .takeaway-bodys>table>tbody>tr>th{
    border: solid 1px;
  }
  .takeaway-bodys>table>tbody>tr>th>button{
    font-size: 1.5vw;
    font-weight: 700;
    width: 10vw;
    height: 4vh;
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
    border-radius: 8px;
    margin-left: 0.5vw;
    padding-left: 0.1vw;
    padding-right: 0.1vw;
    background-image: linear-gradient(to bottom right, red, white);
  }
  .takeaway-bodys>table>tbody>tr:hover{
    background-color: rgb(79, 13, 13);
  }
  .datePicker{
    width: 20vw;
    font-size: 2vw;
    font-weight: 700;
    height: 9vh;
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
    border-radius: 8px;
    margin-left: 0.5vw;
    color: black;
  }