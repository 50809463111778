.get-cost{
    overflow-y: scroll;
    height: 45vh;
}
.Home-cost {
  margin-top: 10px !important;
  display: flex;
  height: 96% !important;
  width: 100%;
}

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: #797979; 
}
::-webkit-scrollbar-thumb {
  background: #FF0000; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #FF0000; 
}
.show-all{
    width: 70vw !important;
    padding-left: 1vw !important;
    padding-right: 1vw !important;
    padding-top: 0vh !important;
    padding-bottom: 1vh;
    color: white;
}
.show-all>.search-cost{	
 color: black;
}	
.show-all>.search-cost>table{	
  width: 68vw;	
}
.show-all>.show-costs::-webkit-scrollbar {
  display: none; /* Webkit tabanlı tarayıcılarda da gizler */
}
.show-all>.show-costs{
margin-top: 2px;
height: 68%;
overflow-x: hidden;
overflow-y: scroll;
padding-bottom: 10px;
}
.show-all>.show-costs>table{
width: 68vw;
}
.show-all>.show-costs>table>tbody>tr>th{
border-bottom: 1px solid;
}
.show-all>.show-costs>table>tbody>tr{
cursor: pointer;
}
.show-all>.show-costs>table>tbody>tr:hover{
background-color: rgb(79, 13, 13);
}
.show-all>.show-costs>table>thead{
background-color: #B92F27;
border-radius: 4px;
}
.show-all>.show-costs>table>thead>tr>th{
border: 1px solid;
word-wrap: break-word;
white-space: normal;
word-break:keep-all;
}
.company-operations-cost{
text-align: start !important;
width: 40vw !important;
color: white;
margin-right: 1vw;
margin-bottom: 0vh !important;
overflow: auto;
scrollbar-width: none !important;
}
.company-operations-cost>button{
font-size: 1rem !important;
font-weight: 700 !important;
width: 40% !important;
height: 5.5vh !important;
margin-bottom: 0.5vh;
border-radius: 8px;
background: #d32f2f !important;
}
.company-operations-cost>button:disabled{
background-color: grey;
background-image: none;
}
.telInput{
border-radius: 16px;
}