.categoryButton {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 5px;
    height: 90vh;
    overflow-y: scroll;
}

.categoryButton > button {
    width: 48%;
    border-radius: 6px;
    font-weight: 500;
    padding: 8px;
    font-size: 1.15rem;
    text-align: center;
    margin-bottom: 6px;
    min-height: 55px;
    border: none;
    margin-left: 2%;
    margin-bottom: 2%;
    cursor: pointer;
    
    word-wrap: break-word;
    white-space: normal;
    word-break: break-word;
}

.categoryBtn {
    width:100%;
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    padding: 5px;
    padding-top: 15px;
    height: 90vh;
    flex-wrap: wrap;
}

.categoryBtn > .mainButtons {
    width: 100%;
    border-radius: 6px;
    font-weight: 500;
    padding: 8px;
    margin-bottom: 5px;
    font-size: 1.15vw;
    text-align: center;
    margin-bottom: 6px;
    min-height: 55px;
    border: none;
    margin-left: 2%;
    margin-bottom: 2%;
    cursor: pointer;
    text-shadow:
        0.11em 0 black,
        0 0.11em black,
        -0.11em 0 black,
        0 -0.11em black;
}


.categoryBtn > .subButtons {
    border-radius: 6px;
    font-weight: 500;
    margin: auto auto 3px auto;
    cursor: pointer;
    border: none;
    font-size: 1vw;
    height: 8vh;
    text-shadow:
        0.07em 0 black,
        0 0.07em black,
        -0.07em 0 black,
        0 -0.07em black;

}