.modalContainer{
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    background-color: white;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);    
    overflow: hidden;
    z-index: 2;
}
.modalCover{
    position: absolute;
    display: none;
    overflow: hidden;
    z-index: 1;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.5;
}
.modalCover.open{
    display: block;
    transform: translate(-50%,-50%);
}
.modalBody {
    overflow: auto;
    height: 94%;
    scrollbar-width: none;
}
.modalBody::-webkit-scrollbar {
    display: none; /* Webkit tabanlı tarayıcılarda da gizler */
  }

.modalContainer.open{
    display: block;
    transform: translate(-50%,-50%);
    animation: openModal 0.3s;
}

.modalContainer .modalHeader{
    background-color: white;
    color: black;
    font-weight: bold;
    padding: 0.300rem;
    padding-left: 2.5rem;
    padding-right: 1rem;
    width: 100%;
    height: 7%;
    display: flex;
    flex-direction: row;
}

.title {
    width: 95%;
    font-weight: bolder;
    font-size: 2vh;
}
.close {
    font-size: 2.5vh;
    font-weight: lighter;
    width: 5%;
    cursor: pointer;
}

.modalContainer .modalHeader .title,.modalContainer .modalHeader .close{
    text-align: center;
    padding: 4px;
}

@keyframes openModal{
    0%   {top: 0%;}
    100% {top: 50%}
}

@keyframes closeModal{
    0%   {top: 50%}
    100% {top: 0%}
}
