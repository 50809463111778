.buttonSelector {
    display: flex;
    flex-direction: column;
    height: 250px;
    overflow-y: scroll;
}
.buttonSelector > div {
    flex: 1;
    height: 100%;
    border-radius: 6px;
    padding: 1.2rem;
    width: 15rem;
    position: relative;
    background-color: hsl(3, 81%, 46%);
    color: white;
    margin-bottom: 6px;
    height: auto;
    border: none;
    cursor: pointer;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.colorPickButton {
    background-color: hsl(3, 81%, 46%);
    border-radius: 6px;
    padding: 1.2rem;
    font-size: 1rem;
    color: white;
}