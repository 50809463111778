.searchcost{
    padding-bottom: 1vh;
    background-color: white;
}
.searchcost>table{
    width: 59vw;
}

.createcustomerbutton {
    left: 1.4vw;
    bottom: 0px;
    position: relative; 
    color: #fff; 
    height: 6vh; 
}