.numpad {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.row {
    margin: auto;
}

.row>button {
    width: 5vw;
    height: 5vw;
    margin-bottom: 0.5vw;
    background-color: #65686D;
    border-radius: 3px;
    color: #fff;
    font-weight: 600;
}

.row>button:hover {
    background-color: #434549;
    color: #fff;
}

.row>button:active {
    background-color: #244A90;
    color: #fff;
}
.refreshText {
    font-weight: 600;
    color: #fff;
    font-size: 1.05vw

}