.oldCheckContainer{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
}
.oldCheckContainer.no-overflow{
    overflow:visible
}
.oldTickButton {
    width: 40%;
    margin: 1vw;
    margin-top: 0;
    height: 2vw;
} 
.changePriceButtons {
    display: flex;
    justify-content: center;
    gap: 0px;
  }
  
  @media screen and (max-width: 850px) {
    .changePriceButtons {
      flex-direction: row;
      align-items: center;
    }
  }
  .changePriceButtons button {
    font-size: 15px;
  }
  
  @media screen and (max-width: 850px) {
    .changePriceButtons button {
      font-size: 12px;
    }
  }
  .orderTable {
    width: 38vw;
    height: 35vh; 
    overflow: auto;
  }
  .orderTableTitle {
    width: 38vw;
  }

  .orderDiv {
    height: 20vh; 
    overflow: auto;
  }
  
  .orderTableDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 51vh;
  }
 
