
.search-show{
  width: 85vw;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: 1vh;
  padding-bottom: 1vh;
  color: white;
}
.search-show>.search{
  background-color: #EC3C33;
}
.search-show>.search>table{
  width: 80vw;
}
.search-show>.show{
  margin-top: 1vh;
  height: 85vh;
  overflow-x: hidden;
  overflow-y: scroll;
}
.search-show>.show>table{
  width: 80vw;
}
.search-show>.show>table>tbody>tr>th{
  border: 1px solid;
}
.search-show>.show>table>tbody>tr{
  cursor: pointer;
}
.search-show>.show>table>tbody>tr:hover{
  background-color: rgb(79, 13, 13);
}
.search-show>.show>table>thead{
  background-color: #B92F27;
  border-radius: 4px;
}
.search-show>.show>table>thead>tr>th{
  border: 1px solid;
  word-wrap: break-word;
  white-space: normal;
  word-break:keep-all;
}
.customer-operations{
  width: 14vw;
  color: white;
  margin-right: 1vw;
  margin-bottom: 1vh;
  overflow: auto;
  scrollbar-width: none !important;
}
.customer-operations>button{
  font-size: large;
  font-weight: 700;
  width: 100%;
  height: 8.9vh;
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
  border-radius: 8px;
  background-color: #B92F27;
  background-image: linear-gradient(to bottom right, red, orange);
}
.customer-operations>button:disabled{
  background-color: grey;
  background-image: none;
}