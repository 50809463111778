.paymentsContainer {
    height: 90vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: px;
    flex-flow: wrap;
    overflow-y: scroll;
    margin-top: 5px;
    align-items: center;
}

.paymentsContainer > article {
    width: 98%;
    min-height: 10vh;
    color: white;
    background-color: red;
    margin-left: 1%;
    margin-bottom: 1vh;
    border-radius: 16px;
    text-align: center;
    font-size: 4vh;
    cursor: pointer;
    padding-top: 2vh;
    padding-bottom: 2vh;
    padding-left: 1vw;
    padding-right: 1vw;
    word-wrap: normal;
    word-break: break-all;
}