.optionmenu {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
}

.optionmenu > div {
    flex: 1;
    height: 100%;
    padding: 0.6rem;
}

.submitOption {
    margin-top: 1rem;
    background-color: green;
    cursor: pointer;
    font-size: 1rem;
    color: white;
    padding: 0.8rem;
    text-align: center;
    border-radius: 12px;
}

.optionmenu > div:last-child {
    border-left: 1px solid white;
}

.optionContainer {
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 2px;
    border-bottom: 1px solid red;
}

.option {
    margin: 3px;
    background-color: hsl(3, 81%, 46%);
    color: white;
    padding: 5px;
    font-weight: bold;
    font-size: 1.05rem;
    width: fit-content;
    height: fit-content;
    border-radius: 10px;
}

.option:hover {
    cursor: pointer;
    transition: 0.2s;
    transform: scale(1.1);
}

.option:not(:hover) {
    transition: 0.1s;
}

.optionName {
    width: 100%;
    font-size: 1rem;
    font-weight: bold;
}

.optionNote {
    display: flex;
    flex-direction: column;
    color: black;
    font-size: 1.2rem;
    font-weight: bold;
}

.clearButton {
    margin-top: 1rem;
    background-color: hsl(3, 81%, 46%);
    cursor: pointer;
    font-size: 1rem;
    color: white;
    padding: 0.8rem;
    text-align: center;
    border-radius: 12px;
}

.optionTitle {
    display: flex;
    flex-direction: column;
    color: black;
    font-size: 1.2rem;
    font-weight: bold;
}

.optionList {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.optionDetail {
    font-size: 0.9rem;
    color: black;
}