.productsContainer {
    height: 90vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: flex-start;
    flex-direction: row;
    flex-flow: wrap;
    overflow-y: scroll;
    margin-top: 4px;
    padding-bottom: 3vw !important;
    margin-bottom: 0 !important;
}

.productsContainer > div {
    box-shadow: 0.255rem 0.255rem 1px rgba(0, 0, 0, 0.3);
    margin: 0px 5px 5px 0px;
    min-width: 28%;
    width: 30%;
    min-height: 11%;
    height: 30%;
    max-height: 19%;
    background-color: #EC3C33;
    border-radius: 20px;
    display: flex;
    overflow: hidden;
    
}

.productsContainer > div > .productMiddle {
    min-width: 60%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}
.editDiv{
    display: inherit !important;
}

.productMiddle > .productName {
    height: 12vh;
    padding: 0.6rem;
    font-weight: bold;
    font-size: 1rem;
    align-self: center;
    text-shadow:
        0.07em 0 black,
        0 0.07em black,
        -0.07em 0 black,
        0 -0.07em black;
}

.productMiddle > .productLeft {
    height: 100%;
    font-size: 0.8rem;
    text-align: center;
    color: white;
    background-color: #ce190f;
}

.productMiddle > .productPrice {
    padding-top: 3px;
    height: 4vh;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    color: white;
    background-color: rgba(0, 0, 0, 0.808);
    justify-content: center;
    width: 100% !important;
}

.productsContainer > div > .productSpecial {
    min-width: 1.3rem;
    width: 15%;
    background-color: rgba(0, 0, 0, 0.425);
    writing-mode: vertical-lr;
    font-weight: bold;
    font-size: 1.4rem;
    display: flex;
    justify-content: center;
    color: white;
}

.productsContainer > div > .productSpecial:hover {
    background-color: white;
    color: black;
    cursor: pointer;
}

.productBox{
    width: 31% !important;
    height: 16vh !important;
   
}