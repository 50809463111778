.discount {
    display: flex;    
    flex-direction: column;
    padding: 1rem;
}

.discountDescription {
    width: 100%;
    background-color: red;
    color: white;
    padding: 1rem;
    height: 100%;
    display: flex;
    flex-direction: row;
    border: none;
}

.discountDescription > div {
    width: 100%;
    max-height: 1rem;
    border: 0px solid;
    outline: none;
}

.discountDescription > span {
    height: 100%;
    width: 100%;
}s

.descriptionTitle {
    font-weight: bold;
    font-size: 1.4rem;
    padding: 0.5rem;
}

.discountAmount {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.discountAmount > .amount {
    width: 60%;
    height: 4.2rem;
    text-align: center;
    background-color: rgb(70, 0, 0);
    color: white;
    font-size: 1.5rem;
    border: none;
    outline: none;
}

.discountAmount > .percent {
    width: 40%;
    font-size: 1.5rem;
    display: flex;
    color: white;
}

.discountAmount > .percent > div {
    flex: 1;
    margin: 5px;
    background-color: rgb(116, 0, 0);
    text-align: center;
    cursor: pointer;
}

.discountAmount > .percent > .percent {
    width: 1rem;
    padding: 1rem;
}

.discountAmount > .percent > .amount {
    width: 1rem;
    padding: 1rem;
}

.submitDiscount {
    width: 100%;
    text-align: center;
    padding: 1rem;
    background-color: green;
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
}