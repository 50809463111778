body {
    background: #485563;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to top, #29323c, #485563);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top, #29323c, #485563);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Home {
    display: flex;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}/* Track */



/* Track */

::-webkit-scrollbar-track {
    -shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 2px;
    border-radius: 10px;
}

.TableAndSections {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    padding-top: 2rem;
}
.TableAndSections .Tables {
    height: 85%;
    overflow-y: hidden;
}

.headerBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 6px;
    width: 100%;
}

.headerBar>div {
    width: 70%;
    padding: 8px;
    height: auto;
    border-radius: 12px;
    text-align: center;
    color: wheat;
    cursor: pointer;
}

.header-bar .TableEdit div {
    display: flex;
    justify-content: center;
}

.header-bar .TableEdit div .btn {
    margin-top: 4px;
    margin-left: 4px;
    font-weight: bold;
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;
    text-align: center;
    width: 50%;
}

.TableAndSections>.Tables>section {
    background: rgba( 255, 254, 254, 0.25);
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37);
    backdrop-filter: blur( 0px);
    -webkit-backdrop-filter: blur( 0px);
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18);
    height: auto;
    height: 92%;
    overflow-y: scroll;
}

.tableArticle {
    color: White;
    position: relative;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    min-width: 120px;
    min-height: 80px;
    text-align: center;
    margin: 4px;
    border-radius: 12px;
    overflow: hidden;
    cursor: pointer;
}

.in {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 4px;
}

.tableTitle {
    display: flex;
    justify-content: space-around;
    font-size: large;
}

.tableDate {
    background-color: white;
    color: black;
    display: flex;
    justify-content: space-around;
    border-radius: 0.4rem;
}

.TableAndSections>.Tables>section>article.round {
    border-radius: 100%;
}

.TableAndSections>.Tables>section>article.oblong {
    min-width: 140px;
    min-height: 80px;
}

.TableAndSections>.sections {
    height: 15%;
    overflow-x: scroll;
}

.caseClosed {
    width: 100%;
    height: 100%;
    font-size: 2.2rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
    font-weight: bold;
    color: white;
    
}

.TableAndSections>.sections>section {
    align-items: center;
    display: inline-flex !important;
    flex-wrap: nowrap;
    flex-direction: row;
    width: 100%;
    height: 80%;
    color: white;
    -webkit-overflow-scrolling: touch;
    
}

.TableAndSections>.sections>section>article:first-child {
    border-radius: 4px;
    background-color: rgb(56, 55, 55);
    margin-left: 0.400rem;
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 18px;
}

.TableAndSections>.sections>section>article {
    min-width: 13vw;
    margin-left: 0.4vw;
    text-align: center;
    font-size: 16px;
    border-radius: 8px;
    font-weight: 600;
    height: 6vh;
    padding: 1%;
    
    word-wrap: normal;
    white-space: normal;
    word-break: break-word;
    cursor: pointer;
}

.Operations {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-content: space-around;
    margin-left: 8px;
    width: 25%;
    height: 100vh;
    padding: 4px;
}

.Operations>.headerBarOperations {
    display: table;
    justify-content: space-between;
    width: 100%;
    background-color: tomato;
    border-radius: 12px;
    margin-top: 2.05rem;
    height: 15%;
}

.Operations>.headerBarOperations>.in div {
    margin-bottom: 6px;
}

.Operations>.headerBarOperations>.in {
    padding: 1rem;
    display: table;
}

.Operations>.headerBarOperations>.in>.profile {
    display: flex;
    flex-direction: row;
    color: white;
    font-size: 1.2vw;
    font-weight: bold;
    width: 100%;
}

.Operations>.headerBarOperations>.in>.profile img {
    width: 80px;
    height: auto;
    margin-right: 6px;
}

.Operations>.headerBarOperations>.in>.date {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: white;
    font-size: 1.2vw;
    font-weight: bold;
    width: 100%;
}

.Operations>.headerBarOperations>.in>.bar {
    background-color: white;
    text-align: center;
    width: 100%;
    border-radius: 8px;
}

.Operations .Buttons {
    margin-top: 1rem;
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: flex-start;
    justify-content: center;
    align-content: center;
    margin-right: 1em;
    align-content: flex-start;
    justify-content: center;
    height: 100%;
}

.Operations .Buttons article {
    background-color: rgb(212, 32, 22);
    width: 40%;
    border-radius: 6px;
    font-weight: 500;
    padding: 8px;
    font-size: 1.15vw;
    text-align: center;
    color: white;
    margin-bottom: 6px;
    min-height: 55px;
    border: none;
    margin-left: 2%;
    margin-bottom: 2%;
    cursor: pointer;
    max-height: fit-content;
    word-wrap: break-word;
    white-space: normal;
    word-break: break-word;
}

.Operations .bButtons {
    display: flex;
    height: 15%;
}

.Operations .bButtons article {
    background-color: #e13e1c;
    width: 100%;
    height: 60px;
    padding: 10px;
    text-align: center;
    border-radius: 4px;
    font-weight: 700;
    font-size: 22px;
    margin: 1%;
    border-radius: 12px;
    cursor: pointer;
    
    word-wrap: break-word;
    white-space: normal;
    word-break: break-word;
}
.OpenCase{
    width: 100vh;
    height: 100vh;
    
}
.OpenCase>form{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.OpenCase>form>button{
    text-align: center;
    background-color: #D52941;
    margin-top: 1rem;
    width: 150px;
    padding: 1.5rem;
    font-size: 0.9rem;
    color: white;
    user-select: none;
    cursor: pointer;
    border-radius: 10px;
}